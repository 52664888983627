import React from "react";
import styled from "styled-components"
import { Link } from "gatsby";
import Img from "gatsby-image";


const ItemThumbnailStyled = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 10px 0px 10px;
    background: ${props => props.theme.colors.primaryAccent};

    @media (max-width: 930px) {
        width: 250px;
      }

      @media (max-width: 710px) {
        width: 100%;
      }
`

const Heading2 = styled.h2`
    font-size: 1.5em;
    font-weight: 600;
    padding: 10px;
    text-align: center;
    width: 70%;
    min-height: 85px;
    color:#313E48;

`

const LinkStyled = styled(Link)`
    width: 100%;
    box-shadow: none;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const GetSome = styled(Link)`
  margin: 20px 0px 20px 0px;
  padding: 20px;
  background: ${props => props.theme.colors.secondaryAccent};
  font-weight: 700;
`

const ImgStyled = styled(Img)`
    width: 100%;
    height: 350px;

    @media (max-width: 900px) {
        height: 250px;
      }
`

const Price = styled.p`
    font-size: 1.2em;
    padding-bottom: 10px;
    color:#313E48;

`

const itemThumbnail = (props) => {
    return (
        <ItemThumbnailStyled>
            <LinkStyled to={props.link}>
                <ImgStyled fluid={props.image} />
                <Heading2>{props.heading}</Heading2>
            </LinkStyled>
            <Price> From ${props.price.toFixed(2)}</Price>
            <GetSome to={props.link}>Order now</GetSome>
        </ItemThumbnailStyled >
    )
}

export default itemThumbnail;

// This is the homepage.

import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import ItemThumbnail from '../components/ItemThumbnail/ItemThumbnail'
import BgImage from '../components/BackgroundImage/BGImage'
import Layout from "../components/layout"
import SEO from "../components/seo"


const Heading1White = styled.h1`
    font-size: 2.4em;
    text-align: center;
    padding: 0px 0px 20px 0px;
    width: 100%;
    margin: auto;
    color:#313E48;
    font-weight:600;

`
const Heading2 = styled.h2`
    font-size: 1.8em;
    text-align: center;    
    padding: 20px 0px 10px 0px;
    width: 100%;
    margin: auto;
    font-weight:600;
    color:#313E48;
`

const Wrapper = styled.div`
    width: 100%;
    max-height:800px;
    background-position: center;
    margin: auto;
`


const Content = styled.div`
    max-width: 600px;
    color:#313E48;
    font-size: 1.3em;
    line-height: 30px;
    text-align: center;
    padding: 10px 10px 10px 10px;
    margin: auto;
 
`

const ThumbnailsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #E7E5DF;
    padding: 40px 40px 40px 40px;

`

const BuyButton = styled.button`
  margin: 0px 0px 50px 20px;
  text-align: center;    
  padding: 20px;
  background: ${props => props.theme.colors.secondaryAccent};
  font-weight: 700;
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const items = data.allMarkdownRemark.edges

    return (

      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All items" />
         <Wrapper> 

 <BgImage
      title="Background Image"
      fluid={data.indexImage.childImageSharp.fluid}
    >

   </BgImage>

          </Wrapper>
         
      <ThumbnailsWrapper>
      <Wrapper> 
  
            <Heading2>Shop Coffee</Heading2> </Wrapper>
        {items.map(({ node }) => {
          const { title, image, shortDescription, price } = node.frontmatter
          return (

            <ItemThumbnail
              key={node.fields.slug}
              link={node.fields.slug}
              heading={title}
              shortDescription={shortDescription}
              image={image.childImageSharp.fluid}
              price={price}
            />
          )
        })}
      </ThumbnailsWrapper>
      </Layout>
    )
  }
}


export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    indexImage: file(relativePath: { eq: "godzilla.webp"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            shortDescription
            price
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
